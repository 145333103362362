const data = {
  projects: [
    {
      id: 1,
      date: '11/15/20',
      title: 'Password Generator 1.1 MDC',
      description: 'Stay safe with secure passwords.',
      repoLink: 'https://www.github.com/mitchellmunderwood/Password-Generator-1.1-MDC',
      liveLink: 'mitchellmunderwood.github.io/Password-Generator-1.1-MDC/',
      tags: [
        { type: 'Skill', name: 'JS' },
        { type: 'Skill', name: 'HTML5' },
        { type: 'Skill', name: 'CSS3' },
        { type: 'Skill', name: 'React' },
        { type: 'Skill', name: 'Material Design' },
      ],
    },
    {
      id: 2,
      date: '11/25/20',
      title: 'React Portfolio 1.2',
      description: 'A minimalist portfolio, built in React.',
      repoLink: 'https://www.github.com/mitchellmunderwood/Portfolio-1.2-React',
      liveLink: 'mitchellmunderwood.github.io/Portfolio-1.2-React/',
      tags: [
        { type: 'Skill', name: 'JS' },
        { type: 'Skill', name: 'HTML5' },
        { type: 'Skill', name: 'CSS3' },
        { type: 'Skill', name: 'React' },
        { type: 'Skill', name: 'Material Design' },
      ],
    },
    {
      id: 3,
      date: '10/20/20',
      title: 'PolitiCandid',
      description: 'Know where people stand on the important issues.',
      repoLink: 'https://www.github.com/mitchellmunderwood/PolitiCandid',
      liveLink: 'mu-politicandid.herokuapp.com/',
      tags: [
        { type: 'Skill', name: 'JS' },
        { type: 'Skill', name: 'HTML5' },
        { type: 'Skill', name: 'CSS3' },
        { type: 'Skill', name: 'React' },
        { type: 'Skill', name: 'Material Design' },
        { type: 'Skill', name: 'Express' },
        { type: 'Skill', name: 'Node.js' },
        { type: 'Skill', name: 'MongoDB' },
      ],
    },
    {
      id: 4,
      date: '8/29/20',
      title: 'Parent Connect',
      description: 'Homeschool together during COVID',
      repoLink: 'https://www.github.com/mitchellmunderwood/Parent-Connect',
      liveLink: 'parent-connect.herokuapp.com/',
      tags: [
        { type: 'Skill', name: 'JS' },
        { type: 'Skill', name: 'HTML5' },
        { type: 'Skill', name: 'CSS3' },
        { type: 'Skill', name: 'Handlebars' },
        { type: 'Skill', name: 'Express' },
        { type: 'Skill', name: 'Node.js' },
        { type: 'Skill', name: 'MySQL' },
      ],
    },

    {
      id: 5,
      date: '7/2/20',
      title: 'Party Planner',
      description: 'Discover meals and cocktails',
      repoLink: 'https://www.github.com/mitchellmunderwood/Party-Planning-Committee',
      liveLink: 'mitchellmunderwood.github.io/Party-Planning-Committee/',
      tags: [
        { type: 'Skill', name: 'JS' },
        { type: 'Skill', name: 'HTML5' },
        { type: 'Skill', name: 'CSS3' },
        { type: 'Skill', name: 'Material Design' },
        { type: 'API', name: 'MealsDB API' },
        { type: 'API', name: 'DrinksDB API' },
      ],
    },
    {
      id: 6,
      date: '8/15/20',
      title: 'Eat-Da-Burger!',
      description: 'Eat delicious burgers',
      repoLink: 'https://www.github.com/mitchellmunderwood/Burger-Buddy',
      liveLink: 'burger-buddy-mu.herokuapp.com/',
      tags: [
        { type: 'Skill', name: 'JS' },
        { type: 'Skill', name: 'HTML5' },
        { type: 'Skill', name: 'CSS3' },
        { type: 'Skill', name: 'Handlebars' },
        { type: 'Skill', name: 'Express' },
        { type: 'Skill', name: 'Node.js' },
        { type: 'Skill', name: 'MySQL' },
      ],
    },
    {
      id: 7,
      date: '6/25/20',
      title: 'Daily Forecast',
      description: 'Get your local weather',
      repoLink: 'https://www.github.com/mitchellmunderwood/Weather-App',
      liveLink: 'mitchellmunderwood.github.io/Weather-App/',
      tags: [
        { type: 'Skill', name: 'JS' },
        { type: 'Skill', name: 'HTML5' },
        { type: 'Skill', name: 'CSS3' },
        { type: 'Skill', name: 'Bootstrap' },
        { type: 'API', name: 'Open Weather API' },
      ],
    },
    {
      id: 8,
      date: '6/8/20',
      title: 'Stack Quiz',
      description: 'Test your JS knowledge',
      repoLink: 'https://www.github.com/mitchellmunderwood/Stack-Quiz',
      liveLink: 'mitchellmunderwood.github.io/Stack-Quiz/',
      tags: [
        { type: 'Skill', name: 'JS' },
        { type: 'Skill', name: 'HTML5' },
        { type: 'Skill', name: 'CSS3' },
      ],
    },
    {
      id: 9,
      date: '5/27/20',
      title: 'Password Generator',
      description: 'Stay safe with secure passwords',
      repoLink: 'https://www.github.com/mitchellmunderwood/Password-Generator',
      deployLink: 'mitchellmunderwood.github.io/Password-Generator/',
      tags: [{ type: 'Skill', name: 'JS' }],
      selected: true,
    },
  ],
};

export default data;
